<template>
  <v-container>
    <div class="document">
      <div class="document-form">
        <v-form ref="form">
          <v-row class="mt-8 mb-6">
            <v-col cols="12" md="6" lg="6">
              <div class="choose-image1">
                <div class="show-image1">
                  <i class="fas fa-file-pdf"></i>
                  <input
                    @change="PreviewPDF"
                    type="file"
                    class="input-file-image1"
                  />
                  <p>{{ $t("document.document File") }}</p>
                  <embed
                    class="embed-pdf1"
                    :src="showPDF || documentItem.url_file"
                    type="application/pdf"
                  />
                </div>
              </div>
              <!--              <label class="error-validate">{{ server_errors.file }}</label>-->
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-row class="mt-2">
                <v-col cols="12" md="12" lg="12">
                  <v-text-field
                    :label="$t('document.document number')"
                    outlined
                    v-model="documentItem.document_number"
                    :hint="`${server_errors.document_number}`"
                    persistent-hint
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="12" class="mt-3">
                  <DatePicker
                    v-model="year"
                    style="width: 100%; !important;"
                    type="year"
                    :placeholder="$t('document.year')"
                  >
                  </DatePicker>
                  <label class="error-validate ml-3" style="font-size: 12px">{{
                    server_errors.year
                  }}</label>
                </v-col>
                <!--            <v-col class="mt-8" cols="12" md="12" lg="12">-->
                <!--              <v-select-->
                <!--                  :label="$t('document.select user')"-->
                <!--                  outlined-->
                <!--                  v-model="documentItem.authorizeUsers"-->
                <!--                  :items="listUser"-->
                <!--                  item-text="userName"-->
                <!--                  item-value="userId"-->
                <!--                  :hint="server_errors.authorizedUsers"-->
                <!--                  persistent-hint-->
                <!--                  multiple-->
                <!--                  dense>-->
                <!--              </v-select>-->
                <!--            </v-col>-->
                <!--            <v-col cols="12" md="12" lg="12">-->
                <!--              <v-text-field-->
                <!--                  :label="$t('document.type')"-->
                <!--                  outlined-->
                <!--                  :items="documentType"-->
                <!--                  item-text="name"-->
                <!--                  item-value="name"-->
                <!--                  v-model="documentItem.type"-->
                <!--                  :hint="server_errors.type"-->
                <!--                  persistent-hint-->
                <!--                  readonly-->
                <!--                  dense>-->
                <!--              </v-text-field>-->
                <!--            </v-col>-->
                <v-col cols="12" md="12" lg="12" class="mt-7">
                  <v-btn
                    class="btn-save-change"
                    @click="ValidateForm"
                    :loading="btnLoading"
                  >
                    {{ $t("document.edit") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

export default {
  props: {
    documentItem: {},
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      showPDF: "",
      imagePDF: "",
      listUser: [],
      year: "",
      file: "",
      btnLoading: false,
      server_errors: {
        document_number: "",
        year: "",
        // authorizedUsers: "",
        type: "",
        file: "",
      },
      documentType: [
        {
          name: "in",
        },
        {
          name: "out",
        },
      ],
    };
  },
  methods: {
    formatYear() {
      this.year = moment(this.documentItem.year, "YYYY-MM-DD").toDate();
    },

    PreviewPDF: function(event) {
      const input = event.target;
      const file = input.files[0];
      const fileType = file["type"];
      const validImageTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];
      if (!validImageTypes.includes(fileType)) {
        this.$notification.ShowErrors(
          "top-right",
          "danger",
          3000,
          "image must be jpeg,png"
        );
      } else if (input.files && input.files[0]) {
        this.imagePDF = input.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.showPDF = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.UploadPDF(this.imagePDF);
      }
    },

    UploadPDF(imagePDF) {
      let formData = new FormData();
      formData.append("imageFile", imagePDF);
      this.$axios.post(`upload-file`, formData).then((res) => {
        if (res.status === 200) {
          this.file = res.data.filename;
        }
      });
    },

    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true;
      }
    },
    // fetchUser() {
    //   this.$axios.get(`company/list-user`).then((res) => {
    //     if (res.status === 200) {
    //       this.listUser = res.data.data.data;
    //     }
    //   })
    // },
    saveChange() {
      const documentItem = {
        file: this.file,
        document_number: this.documentItem.document_number,
        year: moment(this.year).format("YYYY"),
      };
      this.$axios
        .put(`company/edit/document/${this.documentItem.id}`, documentItem)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
          this.btnLoading = false;
          this.$emit("close");
          this.$emit("success");
        })
        .catch((error) => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
              this.$notification.ShowErrors(
                "top-right",
                "danger",
                9000,
                (this.server_errors[key] = value[0])
              );
            }
          }
          this.btnLoading = false;
        });
    },
  },
  created() {
    // this.fetchUser();
    this.formatYear();
  },
};
</script>

<style scoped lang="scss">
// .document {
//   width: 100%;
//   height: 600px;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   .document-form {
//     width: 500px;
//     height: 600px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// }
.choose-image1 {
  width: 100%;
  height: 460px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  .show-image1 {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    border: 2px solid #eeeeee;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    font-family: $font-family;

    i {
      font-size: 4rem;
      color: #1976d2;
    }

    .input-file-image1 {
      position: absolute;
      bottom: 100px;
      height: 100%;
      left: 0;
      opacity: 0;
      outline: none;
      top: 0;
      width: 100%;
      cursor: pointer;
      background-color: red;
      z-index: 9;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    position: absolute;
  }

  .embed-pdf1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    position: absolute;
    overflow-x: hidden;
  }

  .show-remove-image1 {
    position: absolute;
    font-size: 1.5rem;
    color: red;
    top: 5px;
    right: 10px;
    z-index: 10;
    cursor: pointer;
  }
}
</style>
